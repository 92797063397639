import {
  ConfigurableProductVariantFragment,
  ConfigurableProductVariantAttributeFragment,
} from '@/api'

export type ConfigurableVariantAttribute = Record<
  string,
  { label: string; valueIndex: number }
>

export type VariantAttributeRecord = Record<
  string,
  { label: string; valueIndex: number }
>

function createVariantAttribute(
  variantAttributes?: (ConfigurableProductVariantAttributeFragment | null)[],
) {
  return variantAttributes?.reduce((acc, curr) => {
    if (!curr) {
      return acc
    }

    const { code, label, value_index: valueIndex } = curr

    // Attribute code is unique per each variant
    // e.g. variant cannot have multiple flavors at the same time
    if (code && !acc[code]) {
      return {
        ...acc,
        [code]: { label: label ?? '', valueIndex: valueIndex ?? 0 },
      }
    }

    return acc
  }, {} as VariantAttributeRecord)
}

const WEIGHT_UNITS = ['kg', 'g', 'кг', 'г', 'tab', 'таб'] as const
const SIZES = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'XXXXL',
] as const

const hasWeightUnit = (label: string) => {
  return WEIGHT_UNITS.some((unit) =>
    label.toLowerCase().includes(unit.toLowerCase()),
  )
}

const hasMultiplier = (label: string): boolean => {
  return label.toLowerCase().includes('x')
}

const hasSize = (label: string): boolean => {
  return SIZES.some((size) => label.toUpperCase() === size)
}

const getValueFromLabel = (label: string): number => {
  if (hasSize(label)) {
    const size = label.toUpperCase()
    const index = SIZES.findIndex((s) => s === size)
    return index === -1 ? Infinity : index
  }

  if (hasWeightUnit(label)) {
    const value = Number(label.replace(/[^0-9.]/g, '')) || 0
    const lowerLabel = label.toLowerCase()
    const hasOtherUnit = WEIGHT_UNITS.some((unit) => unit === lowerLabel)
    return hasOtherUnit ? value / 1000 : value
  }

  return Infinity
}

export const sortByWeightOrSize = (
  combinations: Array<{ label: string; value: number }>,
) => {
  return combinations.sort((a, b) => {
    const aHasMultiplier = hasMultiplier(a.label)
    const bHasMultiplier = hasMultiplier(b.label)

    if (aHasMultiplier && !bHasMultiplier) return 1
    if (!aHasMultiplier && bHasMultiplier) return -1

    return getValueFromLabel(a.label) - getValueFromLabel(b.label)
  })
}

export function getAvailableAttributeCombinations(
  variants?: (ConfigurableProductVariantFragment | undefined | null)[],
): VariantAttributeRecord[] | undefined {
  return variants
    ?.sort((a) => (a?.is_bestseller ? -1 : 1))
    .reduce((acc, variant) => {
      const variantAttributes = variant?.attributes
      const mappedAttrs = createVariantAttribute(variantAttributes ?? undefined)
      if (!mappedAttrs) {
        return acc
      }
      return [...acc, mappedAttrs]
    }, [] as VariantAttributeRecord[])
}

export function getSortedAttributeCombinations(
  variants?: (ConfigurableProductVariantFragment | undefined | null)[],
): VariantAttributeRecord[] | undefined {
  const combinations = variants?.reduce((acc, variant) => {
    const variantAttributes = variant?.attributes
    const mappedAttrs = createVariantAttribute(variantAttributes ?? undefined)
    if (!mappedAttrs) {
      return acc
    }
    return [...acc, mappedAttrs]
  }, [] as VariantAttributeRecord[])

  if (!combinations) return undefined

  const sortableCombinations = combinations.map((variant) => {
    const weightAttr = Object.values(variant).find((attr) =>
      hasWeightUnit(attr.label),
    )
    const sizeAttr = Object.values(variant).find((attr) => hasSize(attr.label))

    const attributeToSort = sizeAttr || weightAttr
    return {
      label: attributeToSort?.label ?? '',
      value: attributeToSort?.valueIndex ?? 0,
    }
  })

  const sortedAttributes = sortByWeightOrSize(sortableCombinations)

  return sortedAttributes.map(
    (attr) =>
      combinations.find((c) =>
        Object.values(c).some((v) => v.label === attr.label),
      )!,
  )
}

export function getPreselectedVariantAttributes(
  selectedVariantAttributes?: (ConfigurableProductVariantAttributeFragment | null)[],
) {
  return createVariantAttribute(selectedVariantAttributes ?? undefined)
}
