import { ConfigurableProductVariantFragment, ProductStockStatus } from '@/api'
import { ProductConfigurableVariants } from '../configurable-variants'

const sortByRecommended = (
  variant?: ConfigurableProductVariantFragment | null,
  recommendedSimpleIds?: string[],
): number => {
  if (!variant || !recommendedSimpleIds) {
    return 1
  }

  return recommendedSimpleIds?.includes(String(variant?.product?.id ?? ''))
    ? -1
    : 1
}

const sortByBestseller = (
  variant: ConfigurableProductVariantFragment,
): number => {
  return variant?.is_bestseller ? -1 : 1
}

export const filterEnabledVariants = (
  variant: ConfigurableProductVariantFragment,
): boolean => {
  const isEnabled =
    variant?.product?.stock_status === ProductStockStatus.InStock &&
    variant?.product?.enabled &&
    !(variant?.product?.long_term_unavailable ?? false)

  return Boolean(isEnabled)
}

export const getInitialVariant = (
  configurableProductData: ProductConfigurableVariants,
  recommendedSimpleIds?: string[],
): ConfigurableProductVariantFragment | undefined => {
  const initialVariant = configurableProductData?.configurable_variants
    ?.filter(filterEnabledVariants)
    ?.sort(sortByBestseller)
    ?.sort((v) => sortByRecommended(v, recommendedSimpleIds))
    ?.at(0)

  return initialVariant ?? undefined
}
