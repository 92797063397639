'use client'

import { useCartContext } from '@/providers'
import { IconButton, IconButtonProps } from '../icon-button'
import { CartIcon } from '../icons/svg'
import { selectors } from '@/common/constants/selectors-constants'
import { cn } from '@/common/utils/lib'

type ProductDetailVariantsAddToCartButtonProps = {
  buttonText: string
  className?: string
  addToCart?: () => void
} & IconButtonProps

export const ProductDetailVariantsAddToCartButton = ({
  buttonText,
  className,
  addToCart,
  disabled,
}: ProductDetailVariantsAddToCartButtonProps) => {
  const { isCartRefetching, isAddingToCart } = useCartContext()
  const disabledBtn =
    disabled ?? (isCartRefetching || !addToCart || isAddingToCart)

  return (
    <IconButton
      title={buttonText}
      aria-label={buttonText}
      variant="secondary"
      className={cn(
        'h-[50px] w-full md:w-[210px] lg:w-[280px] uppercase',
        className,
      )}
      disabled={disabledBtn}
      onClick={addToCart}
      loaderProps={{
        position: 'end',
        className: 'gap-2',
      }}
      data-test={selectors.PDP.addToCartMain}
      loader={<CartIcon fill="#ffffff" width="30px" height="30px" />}
    >
      {buttonText}
    </IconButton>
  )
}
