import { useCallback } from 'react'

import { gtmTracker } from '../services/tracking/google/gtm-tracker'
import { useAuthContext } from '@/providers'

export function useUserTracking() {
  const { customerData, loaded } = useAuthContext()

  const trackUser = useCallback(() => {
    if (customerData) {
      gtmTracker.trackUserInfo({
        userType: customerData?.group_code ?? undefined,
        userId: customerData?.id ?? undefined,
      })

      if (customerData?.group_code) {
        gtmTracker.trackUserDataSetup({
          email: customerData.email || undefined,
          firstName: customerData.firstname || undefined,
          lastName: customerData.lastname || undefined,
          phone: customerData.addresses?.[0]?.telephone || undefined,
          city: customerData.addresses?.[0]?.city || undefined,
          postalCode: customerData.addresses?.[0]?.postcode || undefined,
          country:
            (customerData.addresses?.[0]?.country_code ??
              customerData.addresses?.[0]?.city) ||
            undefined,
        })
      }
    } else {
      gtmTracker.trackUserInfo({})
    }
  }, [customerData])

  return {
    trackUser,
    loaded,
  }
}
