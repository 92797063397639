'use client'

import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { ConfigurableProductOptionFragment } from '@/api'
import { Field } from '../field'
import { Select } from '../select'
import { consoleError } from '@/common/utils/console'
import './configurable-variant-component.css'
import {
  ConfigurableVariantAttribute,
  sortByWeightOrSize,
} from './utils/get-configurable-variant-attributes'

type Props = {
  disabled: boolean
  label: string
  className?: string
  onChange: (value: ConfigurableVariantAttribute) => void
  availableAttributeCombinations?: ConfigurableVariantAttribute[]
  configurableOptions: (ConfigurableProductOptionFragment | null | undefined)[]
  selectedAttributes?: ConfigurableVariantAttribute
  option?: ConfigurableProductOptionFragment
}

export function ConfigurableVariant({
  disabled,
  className,
  onChange,
  label,
  availableAttributeCombinations,
  configurableOptions,
  selectedAttributes,
  option,
}: Props): JSX.Element {
  const handleOnSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = parseInt(event.target.value, 10)

    if (option?.attribute_code && value) {
      onChange({
        ...selectedAttributes,
        [option.attribute_code]: {
          label:
            weightSortedVariants.find((c) => c.value === value)?.label ?? '',
          valueIndex: value,
        },
      })
    }
  }

  const filteredAttrCombinations = availableAttributeCombinations?.filter(
    (combination) => {
      if (option?.position === 0) {
        return true
      }

      const attrIdx =
        combination[configurableOptions?.[0]?.attribute_code ?? '']?.valueIndex

      const selectedAttrIdx =
        selectedAttributes?.[configurableOptions?.[0]?.attribute_code ?? '']
          ?.valueIndex

      return attrIdx === selectedAttrIdx
    },
  )

  const labels = [
    ...new Set(
      filteredAttrCombinations?.map(
        (attr) => attr[option?.attribute_code ?? '']?.label,
      ),
    ),
  ]

  const values = [
    ...new Set(
      filteredAttrCombinations?.map(
        (attr) => attr[option?.attribute_code ?? '']?.valueIndex,
      ),
    ),
  ]

  const combined = labels.map((label, index) => ({
    label,
    value: values[index],
  }))

  const weightSortedVariants = useMemo(
    () => sortByWeightOrSize(combined),
    [combined],
  )

  // Always use sorted variants
  const isSelectedInFilteredCombinations = weightSortedVariants
    .map(({ value }) => value)
    .includes(
      selectedAttributes?.[option?.attribute_code ?? '']?.valueIndex ?? 0,
    )

  if (!isSelectedInFilteredCombinations && option?.attribute_code) {
    consoleError('configurable-variant', {
      message: 'Selected attribute is not in the filtered combinations',
      option,
      selectedAttributes,
      filteredAttrCombinations,
    })
  }

  const selectedValue = isSelectedInFilteredCombinations
    ? selectedAttributes?.[option?.attribute_code ?? '']?.valueIndex ?? 0
    : weightSortedVariants[0]?.value

  return (
    <div className={twMerge('text-left', className)}>
      <Field required label={label}>
        <Select
          onChange={handleOnSelectChange}
          value={selectedValue}
          className="w-full text-sm min-h-[28px] mt-1 appearance-none bg-white bg-no-repeat pr-[2em] variants-dropdown"
          aria-label={label ?? ''}
          disabled={disabled}
        >
          {weightSortedVariants.map((variant) => (
            <option key={variant.value} value={variant.value ?? undefined}>
              {variant.label}
            </option>
          ))}
        </Select>
      </Field>
    </div>
  )
}
